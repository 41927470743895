.dashboard-box {
  height: 200px;
  text-align: center;
  cursor: pointer;
  color: white !important;
  transition: 0.3s all ease !important;
}
.dashboard-box:hover {
  transform: scale(1.015);
}
.orders {
  background: linear-gradient(
    90deg,
    rgba(0, 129, 114, 1) 0%,
    rgba(0, 184, 124, 1) 100%
  ) !important;
}

.products {
  background: linear-gradient(
    91deg,
    rgba(108, 0, 144, 1) 0%,
    rgba(173, 0, 255, 1) 100%
  ) !important;
}

.sales {
  background: linear-gradient(
    90deg,
    rgb(146 143 0) 0%,
    rgb(205 199 32) 100%
  ) !important;
}

.users {
  background: linear-gradient(
    91deg,
    rgb(183 63 0) 0%,
    rgb(246 133 32) 100%
  ) !important;
}

.box-list {
  font-weight: 400;
  margin: 0.25rem 0;
}
