.custom-file-upload {
  display: inline-block;
  padding: 0;
  cursor: pointer;
  height: 150px;
  width: 150px;
  background-size: cover;
  background-position: center;
  text-align: right;
  margin: 10px;
}
.uploading {
  cursor: progress;
}

@media screen and (max-width: 480px) {
  .custom-file-upload {
    height: 100px;
    width: 100px;
  }
}
